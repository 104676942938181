import React , { useState , useEffect } from "react";
import axios from 'axios';


const PatternSelector = ({ onSelectPattern }) => {
    
    const [patterns , setPatterns] = useState([]);
    const [selectedPattern , setSelectedPattern] = useState('');

    useEffect( () => {
        fetchPatterns();  //fetch patterns when component mounts
    }, []);

    const fetchPatterns = async () => {
        try {
            const response = await axios.get('https://api.qr-tiger.com/v1/patterns' , {
                headers : {
                    Authorization : 'Bearer 17136810-c264-11ee-8f57-d181f4a9adb5',
                },
            });
            setPatterns(response.data);
        }catch(error) {
            console.error("Error fetching patterns", error)
        }
    };

    const handlePatternChange = (patternId) => {
        setSelectedPattern(patternId);
        onSelectPattern(patternId);
    };



  return (
    <div>
        <select value={selectedPattern} onChange={(e) => handlePatternChange(e.target.value)}>
            <option value="">SelectPattern</option>
            {patterns.map((pattern) => (
                <option key={pattern.id} value={pattern.id}>
                    {pattern.name}
                </option>
            ))}
        </select>
    </div>
  )
}

export default PatternSelector;