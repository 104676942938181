import React , { useState , useEffect } from "react";
import axios from 'axios';

const FrameSelector = ({ onSelectFrame }) => {
    const [frames , setFrames] = useState([]);
    const [selectedFrame , setSelectedFrame] = useState('');

    useEffect(() => {
        fetchFrames();
    }, []);

    const fetchFrames = async () => {
        try{
            const response = await axios.get('https://api.qr-tiger.com/v1/frames', {
                headers: {
                    Authorization: 'Bearer 17136810-c264-11ee-8f57-d181f4a9adb5', 
                },
            });
            setFrames(response.data);
        } catch(error) {
            console.error('Error Fetching Frames : ' , error)
        }
    };

    const handleFrameChange = (frameId) => {
        setSelectedFrame(frameId);
        onSelectFrame(frameId)
    };

    return(
        <div>
            <select value = {selectedFrame} onChange = { (e) => handleFrameChange(e.target.value)}>
                <option value="">Select Frame</option>
                {frames.map((frame) => (
                    <option key={frame.id} value={frame.id}>
                        {frame.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default FrameSelector;